<template>
    <div class="h-full pb-10">
        <div class="flex gap-2 items-center">
            <h3 class="text-xl font-bold">{{$route.name}}</h3>
        </div>

        <div class="content bg-white shadow rounded mt-2 p-3 h-full">
            <div class="home container pt-0 h-full">
                <iframe src="https://darkside-developments.com/item_aht/" style="width: 100%; height: 100%;"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";
export default defineComponent({
    name: "Logs",
});
</script>

<style lang="scss" scoped>
</style>
